import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { ThemeProvider } from 'styled-components'
import theme from './styles/theme.js'
import { Box } from 'grid-styled';
import ScrollableAnchor from 'react-scrollable-anchor'

import Header from './header'
import Footer from './footer'

import { configureAnchors } from 'react-scrollable-anchor'
configureAnchors({offset: -30})

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <ThemeProvider theme={theme}>
        <React.Fragment>
          <Helmet
            title={`${data.site.siteMetadata.title} – Gîte et hébergement en Bresse bourguignonne`}
            meta={[
              { name: 'description', content: 'Hébergement et vacances, ressourcement individuel ou en groupe, avec ou sans accompagnement. Coaching, supervision, thérapies brèves ou énergétiques (personnes et lieux), présence socio-éducative (handicap), tai-chi, qigong.' },
            ]}
          >
            <html lang="fr" />
          </Helmet>
          <ScrollableAnchor id="accueil">
            <Box width={1} p={3} mt={3}>
              <Header />
              {children}
              <Footer />
            </Box>
          </ScrollableAnchor>
        </React.Fragment>
      </ThemeProvider>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
