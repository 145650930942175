import React from 'react'
import { Link } from 'gatsby'
import { Flex } from 'grid-styled'
import { CenteredBox, Image } from './styles/styled-system'

import Menu from './menu'

import LogoImage from '../images/grange-a-outeaux-logo-image.svg'
import LogoText from '../images/grange-a-outeaux-logo-text.svg'

const CenteredBoxCustom = (props) => (
  <CenteredBox width={[1, 2/3, 1/2]} {...props}>{props.children}</CenteredBox>
)

const Header = () => (
  <React.Fragment>
    <Flex justifyContent="center">
      <CenteredBoxCustom mb="-1px">
        <Link to="/">
          <Image src={LogoImage} alt="Logo gite la grange a outeaux" />
        </Link>
      </CenteredBoxCustom>
    </Flex>
    <Flex bg="beige.light" justifyContent="center" flexWrap="wrap">
      <CenteredBoxCustom my={3} style={{textAlign:'center'}}>
        <Link to="/">
          <Image width={62/100} src={LogoText} alt="Logo gite la grange a outeaux - texte" display="inline-block" />
        </Link>
      </CenteredBoxCustom>
    </Flex>
    <Menu />
  </React.Fragment>
)

export default Header
