import React from 'react'
import styled from 'styled-components'
import { themeGet, display } from 'styled-system'
import { Flex } from 'grid-styled';
import { Box, Title, Typography, SubLink, Image, Close } from './styles/styled-system'

import LogoImage from '../images/grange-a-outeaux-logo-image.svg'
import flecheBas from '../images/fleche-bas.svg'
import facebook from '../images/Facebook.svg'
import trigger from '../images/trigger.svg'


const MenuContainer = styled(Flex)`
  left: 0;
  width: 100%;
  z-index: 100;
  ${props => props.fix ? `
    position: fixed;
    top: 0;` 
  : `
    position: absolute;
    `
  }
`
MenuContainer.defaultProps = {
  blacklist: Flex.defaultProps.blacklist.concat(['fix'])
}

const Trigger = styled(Flex)`
  ${display};
  cursor: pointer;
  border: 0;
  background: white;
  transform: translateX(${props => props.visible ? 0:'40'}%);
  transition: all ${themeGet('timings.speed','.4s')} ${themeGet('transition.ease', 'ease')};
`
Trigger.defaultProps = {
  blacklist: Flex.defaultProps.blacklist.concat(['display', 'visible'])
}


const SubMenuContainer = styled(Box)`
  text-align: center;
  justify-content: center;
`

const MenuLinks = styled(Flex)`
  ${display};
  width: 100%;
  text-align: center;
  @media (max-width: ${themeGet('breakpoints.1', '40em')}) {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: white;
    flex-direction: column;
    transform: translateX(${props => props.show ? 0:'-100'}%);
    transition: all ${themeGet('timings.speed','.4s')} ${themeGet('transition.ease', 'ease')};
  }
`
MenuLinks.defaultProps = {
  blacklist: Flex.defaultProps.blacklist.concat(['show', 'display'])
}

const MenuLink = ({href, target, active, icon, children, hasChild, onClick}) => (
  <Title is="a" href={href} target={target} mb={0} px={[2,0,2]} py={[1,2]} fontSize={'12px'} color={active?'black':'beige.dark'} onClick={onClick}>
    { icon 
      ? <Image src={icon} height=".9rem" alt={children} width="auto" display="inline-block" />
      : children  
    }
    {hasChild && 
      <Image width={'.8rem'} src={flecheBas} display={['none', 'inline-block']} ml={1} alt="flêche bas" />
    }
  </Title>
)

const SubMenu = styled(Box)`
  position: absolute;
  display: none;
  top: 100%;
  &:before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 0px;
    height: 0px;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid ${themeGet('colors.beige.light', 'white')};
  }
  ${SubMenuContainer}:hover & {
    display: block;
  }

  @media (max-width: ${themeGet('breakpoints.1', '40em')}) {
    display: block;
    position: relative;
    top: ${themeGet('space.2', 10)}px;
    &:before {
      border-bottom-color: ${themeGet('colors.beige.ultralight', 'white')};
    }
  }
`

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fix: 0,
      show: 0,
      hash: null
    };
    this.menuRef = React.createRef();
    this.links = [
      { href: '/#accueil',     name: 'Accueil' },
      { href: '/#prestations',     name: 'Prestations' },
      { href: '/#qui-sommes-nous', name: 'Qui sommes-nous?' },
      { href: '/#la-grange', name: 'Les lieux', children: [
        { href: '/#la-grange',       name: 'La grange' },
        { href: '/#galeries',       name: 'Galeries' },
        { href: '/#la-region',       name: 'La région' },
      ]},
      { href: '/#nous-trouver', name: 'Contact' },
      // { href: '/actualites', name: 'Actualité' },
      { href: '/#documents', name: 'Liens' , children: [
        { href: '/#documents', name: 'Documents utiles' },
        { href: '/#amis', name: 'Amis & partenaires' }
      ]},
      { 
        href: 'https://www.facebook.com/La-Grange-%C3%A0-outeaux-2391394994458001/', 
        name: 'Facebook', 
        icon: facebook,
        target: '_blank'
      }
    ];
    this.handleScroll = this.handleScroll.bind(this)
    this.handleHashChange = this.handleHashChange.bind(this)
    this.hideMenu = this.hideMenu.bind(this)
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('hashchange', this.handleHashChange);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('hashchange', this.handleHashChange);
  }

  handleScroll(e) {
    const node = this.menuRef.current;
    const { y } = node.getBoundingClientRect();
    this.setState({fix: y < 0 ? 1 : 0 });
  }
  handleHashChange(e) {
    this.setState({hash: e.target.location.hash});
  }
  hideMenu(e) {
    this.setState({show: 0});
  }

  render() {
    const { fix, show, hash } = this.state;
    return (
      <div ref={this.menuRef}>
        <MenuContainer fix={fix} bg={['transparent', null, 'white']} justifyContent="center" flexWrap="wrap">
          <Trigger visible={!show} width={1} is="button" px={2} py={1} onClick={() => this.setState({show:1})} alignItems="center" display={['flex', null, 'none']} justifyContent="center" m={0}>
            <Image src={trigger} alt="Menu" display="inline-block" width="1rem" pr={2} />
            <Typography is="span" mb={0}>Menu</Typography>
          </Trigger>
          <MenuLinks width={1} justifyContent="center" color="beige.dark" flexWrap="wrap" show={show} alignItems="center" display={['flex', null, 'block']}>
            <Close display={['block',null,'none']} onClick={this.hideMenu} />
            <Image src={LogoImage} alt="Logo grange" width={1} display={['inline-block', null, 'none']} mb={3} />
            { this.links.map((link, index) => {
              if (link.children) {
                return (
                  <SubMenuContainer pt={2} pb={[3,2]} key={index} width={[1,null, 'auto']} display={['block', 'inline-flex']}>
                    <MenuLink key={index} target={link.target} href={link.href} active={link.href===hash} hasChild onClick={this.hideMenu} icon={link.icon}>{link.name}</MenuLink>
                    <SubMenu bg={['beige.ultralight', "beige.light"]} pt={3} pb={2} px={3}>
                      { link.children.map((subLink, subIndex) => (
                        <SubLink key={`${index}-${subIndex}`} href={subLink.href} onClick={this.hideMenu}>{subLink.name}</SubLink>
                      ))}
                    </SubMenu>
                  </SubMenuContainer>
                )
              } else {
                return <MenuLink key={index} target={link.target} href={link.href} active={link.href===hash} icon={link.icon} onClick={this.hideMenu}>{link.name}</MenuLink>
              }
            })}
          </MenuLinks>
        </MenuContainer>
      </div>
    )
  }
}

export default Menu
