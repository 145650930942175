import React from 'react'
import styled from 'styled-components'
import system from 'system-components'
import { Link as L } from 'gatsby'
import { Field as FormikField, ErrorMessage as FormikErrorMessage } from 'formik'
import { themeGet } from 'styled-system'
import theme from './theme.js';
import tag from 'clean-tag'
import { Box as B } from 'grid-styled'

import { RichText as PrismicReactJS } from 'prismic-reactjs';
import { RichText as PrismicRichText } from 'prismic-dom'

import {
  color,
  maxWidth,
  space,
  width,
  height,
  fontSize,
  fontStyle,
  fontWeight,
  fontFamily,
  textAlign,
  position,
  zIndex,
  display,
  top, 
  left,
  bottom,
  flexBasis,
  overflow,
  right,
  borders
} from 'styled-system'

import plus from '../../images/plus.svg'

const Elements = PrismicRichText.Elements;

export const Container = styled(tag)`
  ${maxWidth}
  ${position}
  ${zIndex}
  ${display}
  ${overflow}
  ${top}
  ${left}
  ${bottom}
  ${right}
  ${width}
  ${height}
  ${borders}
  ${space}
  ${textAlign}
`

export const CenteredBox = (props) => {
  const defaults = {
    width: [5/6, 2/3, 1/2]
  }
  return <B {...{...defaults, ...props}}>{props.children}</B>
}

export const Box = styled(B)`
  ${display};
  ${position};
  ${textAlign};`

export const Typography = system({
  is: 'p',
  mt: 0,
  mb: 2,
  fontSize: 2,
  fontWeight: 'light',
  lineHeight: 2,
  fontFamily: 'body',
  color: 'inherit',
  textAlign: 'left'
}).extend`
  ${display}
  ${flexBasis}
  ${width}
  ${fontStyle}
`
Typography.defaultProps.blacklist.push('width', 'display')

export const Title = (props) => {
  const defaults = {
    is: 'h1',
    fontSize: 3,
    fontFamily: 'heading',
    fontWeight: 'regular',
    textAlign: 'center',
    mb: 3,
    color: 'beige.dark',
    css: {
      textTransform: 'uppercase',
      textDecoration: 'none'
    }
  }
  return <Typography {...{...defaults, ...props}}>{props.children}</Typography>
}
Title.defaultProps = {
  blacklist: tag.defaultProps.blacklist.concat(['css'])
}

export const SubLink = (props) => {
  const defaults = {
    is: 'a',
    fontSize: '11px',
    display: 'block',
    mb: [2,1]
  }
  return <Title {...{...defaults, ...props}}>{props.children}</Title>
}

export const Link = styled(L)`
  ${fontWeight}
  ${color}`;

export const Bold = styled(tag.b)`
  font-weight: ${themeGet('fontWeights.semiBold', 600)};
  ${color}
`

export const Span = styled(tag.span)`
  ${color}
`

export const Exerg = styled(tag.span)`
  ${fontWeight}
  text-decoration: underline;
`

export const Br = styled(tag.br)`
  ${display}
`

export const Ul = styled.ul`
  padding-top: 0;
  padding-left: 19px;
  padding-bottom: ${themeGet('space.2', 8)}px;
`

export const Li = (props) => {
  const defaultProps = {
    is: 'li',
    mb: 0,
    css: {
      listStyle: 'disc'
    }
  }
  return <Typography {...{...defaultProps,...props}}>{props.children}</Typography>
}

export const Image = styled(tag.img)`
  ${display}
  ${width}
  ${height}
  ${position}
  ${space}
`
Image.defaultProps = {
  ...Image.defaultProps,
  width: 1,
  height: 'auto',
  display: 'block',
  blacklist: [
    ...tag.defaultProps.blacklist
  ]
};

const CloseButton = styled(tag.button)`
  ${display};
  position: absolute;
  right: ${themeGet('space.3',10)}px;
  top: ${themeGet('space.3', 10)}px;
  background: none;
  border: 0;
  cursor: pointer;
  z-index: 10;
  ${Image} {
    transform: rotate(45deg);
  }

  @media (max-width: ${themeGet('breakpoints.0')}) {
    padding: ${themeGet('space.3', 10)}px;
    top: 6vw;
    right: 6vw;
  }
`
export const Close = (props) => (
  <CloseButton {...props}>
    <Image src={plus} alt="close" width="1rem" />
  </CloseButton>
)

Close.defaultProps = {
  blacklist: tag.defaultProps.blacklist
}

const field = `
  box-sizing: border-box;
  border: 0;
  color: ${theme.colors.beige.dark};
  background: ${theme.colors.beige.ultralight};
  &::-webkit-input-placeholder {
    color: ${theme.colors.beige.medium};
    font-style: italic;
  }
  &:focus {
    background: white;
  }
`

export const Input = styled(tag.input)`
  ${space};
  ${width};
  ${fontSize};
  ${field};
  ${color};
`
const inputProps = {
  px: 2,
  py: 1,
  color: '#ff00ff',
  width: 1,
  fontSize: 1
}
Input.defaultProps = {...{...Input.defaults, ...inputProps}};

export const Field = styled(tag.input)`
  ${space};
  ${width};
  ${fontSize};
  ${field};
`
Field.defaultProps = {...{
  ...Field.defaults, ...{
    is: FormikField,
    ...inputProps
  }}
};

export const ErrorMessage = (props) => {
  return <FormikErrorMessage component={Typography} {...props} />
}

export const buttonHover = `
  background: rgba(20,20,20,.8);
  .stroke {
    stroke: white;
  }
`
export const Button = styled(tag)`
  ${width};
  ${borders};
  ${space};
  ${color};
  ${fontSize};
  ${fontWeight};
  ${fontFamily};
  background: ${themeGet('colors.beige.medium','beige')};
  display: inline-block;
  box-sizing: border-box;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    ${buttonHover}
  }
`

Button.defaultProps = {
  ...{...Button.defaults, ...{
    px: 3,
    py: 1,
    border: 1,
    color: 'white',
    fontWeight: 'light',
    fontFamily: 'heading'
  }}
};

export const htmlSerializer = function (type, element, content, children) {
  const key = JSON.stringify(element);
  switch(type) {
    // Add a class to paragraph elements
    case Elements.paragraph:
      return <Typography key={key} is="p">{ children.map(child=>child) }</Typography>
    case Elements.em:
      return <Typography key={key} is="em" fontStyle="italic">{ children.map(child=>child) }</Typography>
    case Elements.strong:
      return <Typography key={key} is="strong" fontWeight="bold">{ children.map(child=>child) }</Typography>
    case Elements.list:
      return <Ul key={key}>{ children.map(child=>child) }</Ul>
    case Elements.listItem:
      return <Li key={key}>{ children.map(child=>child) }</Li>

    // Return null to stick with the default behavior for all other elements
    default:
      return null;
  }
};

export const RichText = ({render}) => (
  <PrismicReactJS render={render} htmlSerializer={htmlSerializer} />
)