// theme
import { injectGlobal } from 'styled-components'
import 'typeface-hind'
import 'typeface-reem-kufi'
import reset from 'styled-reset'

injectGlobal`
  ${reset}
`
const transition = {
  ease: 'cubic-bezier(0.71, 0.1, 0.43, 0.99)'
}

const timings = {
  speed: '.5s',
  slow: '1s'
}

const breakpoints = [ '40em', '52em', '64em' ];

const colors = {
  black: '#000000',
  white: '#fff',
  beige: {
    ultralight: '#f4f3f2',
    light: '#EBE8E4',
    dark: '#796857',
    medium: '#B5A89A',
    opaque: 'rgba(235,232,228,.7)'
  }
}

// const fontSizes = inDesignFontSize.map(fs => (fs*pts2pxRatio))
const fontSizes = [10, 14, 16, 23];

const fonts = {
  heading: "Reem Kufi",
  body: "Hind"
}

const lineHeights = [
  0, 1, 1.5
]
const fontWeights = {
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700
}

export const space = [ 
  0,
  4,
  8,
  16,
  32,
  64,
  96,
  128
]

export const theme = {
  transition,
  timings,
  breakpoints,
  colors,
  space,
  fonts,
  fontSizes,
  lineHeights,
  fontWeights
}

export default theme
