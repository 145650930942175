import React from 'react'
import { Flex, Box } from 'grid-styled'
import { CenteredBox, Link, Image, Typography } from './styles/styled-system'
import LogoImage from '../images/grange-a-outeaux-logo-footer.svg'

const Footer = ({ siteTitle }) => (
  <>
    <Flex  justifyContent="center" mt={4}>
      <CenteredBox>
        <Link to="/">
          <Image mb="-1px" src={LogoImage} alt="Logo Grange à Outeaux" />
        </Link>
      </CenteredBox>
    </Flex>
    <Flex  bg="beige.medium" justifyContent="center" flexWrap="wrap" color="white" py={3}>
      <Box px={2}>
        <Typography is="div" fontSize={1} textAlign={'center'} display={['block','inline-block']}>
          <address>La Grange à outeaux (SAS), 1590, route de Durtal, Lieu-dit La Petite-Grange</address>
          71470 Montpont-en-Bresse, France<br />
          Siret N° 831.685.821.00017 | RCS Chalon-sur-Saône | APE 5520Z <br />
          <Link to="/mentions-legales" color="white">Mentions légales</Link>
        </Typography>
      </Box>
    </Flex>
  </>
)

export default Footer
